import { useState, useEffect, useRef } from 'react'
import { Link  } from 'react-router-dom';
import TextField from '../components/Form/TextField';
import MetaData from '../layouts/MetaData';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Spinner} from 'react-bootstrap';
import {isVerificationSaved, sendOTPCode, staffLogin} from '../actions/authActions';
import {HIDE_ALERT_MESSAGE} from '../constants/Common';
import loginLogo from '../assets/Images/loginIcon.png';
import ReCapTCHA from "react-google-recaptcha";
import keys from '../keys';


const StaffLogin = ({ history }) => {

	const alert = useAlert();
	const dispatch = useDispatch();

	const {error : loginError } = useSelector(state => state.common);
	const [show, setShow] = useState(false);
	let forAccount = "doctor";

	// recaptcha
	const [verified, setVerified] = useState(false);
    const [recaptchaVal, setRecaptchaVal] = useState('');
	const captchaRef = useRef();

	const validate = Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Email is Required'),
		password: Yup.string()
		  .min(6, 'Too Short!')
		  .max(20, 'Too Long!')
		  .required('Password is Required')
	  });

	const { isAuthenticated, error, loading, user } = useSelector(state => state.auth);

	useEffect(() => {
		// Disable autocomplete for email and password fields
		const emailInput = document.getElementById('email');
		if (emailInput) {
		  emailInput.setAttribute('autocomplete', 'new-email');
		}

		const passwordInput = document.getElementById('password');
		if (passwordInput) {
		  passwordInput.setAttribute('autocomplete', 'new-password');
		}

		if(isAuthenticated === true && user.role === 'doctor'){
			if(user?.verified === true ){
				history.push("/doctor/dashboard");
			} else {

				history.push({
					pathname: "/auth/verify",
					state: {_id: user?._id, email: user?.email, forAccount: forAccount}
				});

				dispatch(sendOTPCode(user?._id, user?.email, forAccount));
			}
		} else {
			history.push("/auth/signin/doctor");
		}



		if(error){
			alert.error(error);

			dispatch({
                type: HIDE_ALERT_MESSAGE
              })
		}

		if(loginError) {
			alert.error(loginError);

			dispatch({
                type: HIDE_ALERT_MESSAGE
              })
		}

	}, [dispatch, alert, isAuthenticated, error, loginError, history])

	function onChange(value) {
		setVerified(true);
		setRecaptchaVal(value);
	}

	const submitHandler = (values) => {
        dispatch(staffLogin(values, recaptchaVal));
		captchaRef.current.reset();
	}

    return (
    	<>
		<MetaData title="Sign In" />
		 <>
				<div className="login-section">
					<div className="text-center text-white mt-4">
						<h1 className="display-4">Signing in as Provider</h1>
						<p>Provide valid login credentials to login to your account.</p>

					</div>
					<div className="container bg-white" style={{marginTop: '3%', width: '45%', borderRadius: '10px'}}>
						<div className="col-md-12" >
							<br />

							<div className="text-center">
								<img src={loginLogo} className='img-responsive loginIcon'/>
							</div>

							<Formik initialValues={{
								email: '',
								password: ''
							}}
								validationSchema={validate}
								onSubmit={values => {
								submitHandler(values)
							}}
							>
								<div style={{margin: '0px 80px'}}>
									<Form>
										<TextField
											label="Email"
											name="email"
											type="email"
											id="email"
											placeholder="Enter your email address"
										/>

										<TextField
											label="Password"
											name="password"
											id="password"
											type={show === false ? "password" : "text"}
											placeholder="XXXXXXXXXXX"
										/>

										<div style={{float: 'right'}}>
											<input type="checkbox"
												name="show"
												id="show"
												value={show}
												onChange={() => setShow(!show)}
											/>
											&nbsp;&nbsp;<label htmlFor="show">Show Password</label>
										</div>

										<br/>
										<ReCapTCHA
											sitekey={keys?.SITE_KEY}
											onChange={onChange}
											ref={captchaRef}
											style={{
												transform:'scale(0.99)',
												zoom: '112%'
											}}
										/>
										<br />

										{/* <button className="reset-btn" type="reset"><i className='bx bx-reset' ></i></button> */}
										<div className="text-center">
										<button className="loginScreenBtn" type="submit">
											{loading ? <Spinner animation="border" style={{height: '20px', width: '20px'}}/> :
											'Sign In'}
										</button>

										<br/><br/>

											<p><Link to="/" className="link">www.rpmatrix.com</Link></p>
											<br/>
											</div>

									</Form>
								</div>
							</Formik>
						</div>
					</div>
				</div>
		</>
    </>
    )
}

export default StaffLogin
