import axios from 'axios';
import { Prod01 } from '../constants/ActionType';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    STAFF_LOGOUT_SUCCESS,
    STAFF_LOGOUT_FAIL,
    ADMIN_PASSWORD_UPDATE_REQUEST,
    ADMIN_PASSWORD_UPDATE_FAIL,
    HR_LOGOUT_SUCCESS,
    HR_LOGOUT_FAIL,
    ASSISTANT_LOGOUT_SUCCESS,
    ASSISTANT_LOGOUT_FAIL,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAIL,
    CLEAR_ERRORS
} from '../constants/authConstants';

import {
    FETCH_START,
    SHOW_ALERT_MESSAGE,
    HIDE_ALERT_MESSAGE,
    VERIFIED_ACCOUNT_SUCCESS,
    VERIFIED_ACCOUNT_FAILED,
    FETCH_END,
    FETCH_ERROR
} from '../constants/Common';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useHistory} from "react-router-dom";
import StandardErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";


// Login
export const login = (values, recaptchaVal) => async(dispatch) => {
    const { email, password} = values;

    try {
       dispatch({
           type: LOGIN_REQUEST
       })

       const res  = await axios.post(`${Prod01}/admin/login`, {
           email,
           password,
           recaptchaValue: recaptchaVal
       });

       if (res.data) {
        axios.defaults.headers.common["authorization"] = "Bearer " + res.data.tokens.access.token;
        localStorage.setItem(
          "token",
          JSON.stringify(res.data.tokens.access.token)
        );
        let toAdd = {...res.data.Admin,timeStamp: new Date().getTime()};
        localStorage.setItem(
          "admin",
          JSON.stringify(toAdd)
        );
        localStorage.setItem(
            "userType",
            JSON.stringify("admin")
        )
       }

       dispatch({
           type: LOGIN_SUCCESS,
           payload: res.data.Admin
       })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Invalid email or password'
          })
          dispatch({
              type: LOGIN_FAIL
          })
    }
}

// Staff Login
export const staffLogin = (values, recaptchaVal) => async(dispatch) => {
    const {email, password} = values;

    let res;

    try {
       dispatch({
           type: LOGIN_REQUEST
       })

       res = await axios.post(`${Prod01}/doctor/login`, {
        email: email,
        password: password,
        recaptchaValue: recaptchaVal
       });

       if (res.data) {
        axios.defaults.headers.common["authorization"] = "Bearer " + res.data.tokens.access.token;

        localStorage.setItem(
          "token",
          JSON.stringify(res.data.tokens.access.token)
        );
       let toAdd = {...res.data.doctor,timeStamp: new Date().getTime()};
       localStorage.setItem(
           "doctor",
           JSON.stringify(toAdd)
       );
        localStorage.setItem(
            "userType",
            JSON.stringify("doctor")
        )
       }

       dispatch({
           type: LOGIN_SUCCESS,
           payload: res.data.doctor
       })

    } catch (error) {
        if(error.message === "Request failed with status code 401"){
            dispatch({
                type: LOGIN_FAIL,
                payload: "Invalid username or password"
            })
        } else if(error.message === "Request failed with status code 500"){
            dispatch({
                type: LOGIN_FAIL,
                payload: "Invalid username or password"
            })
            dispatch({
                type: LOGIN_FAIL,
                payload: "Invalid username or password"
            })
        }
    }
}

// HR Login
export const hrLogin = (values, recaptchaVal) => async(dispatch) => {
    const {email, password} = values;

    try {
       dispatch({
           type: LOGIN_REQUEST
       })

       const res = await axios.post(`${Prod01}/hr/login`, {
        email : email,
        password : password,
        recaptchaValue: recaptchaVal
       });

       if (res.data) {
        axios.defaults.headers.common["authorization"] = "Bearer " + res.data.tokens.access.token;

        localStorage.setItem(
          "token",
          JSON.stringify(res.data.tokens.access.token)
        );
       let toAdd = {...res.data.hr,timeStamp: new Date().getTime()};
       localStorage.setItem(
           "hr",
           JSON.stringify(toAdd)
       );
        localStorage.setItem(
            "userType",
            JSON.stringify("hr")
        )
       }

       dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.hr
    })

    } catch (error) {
        if(error.message === "Request failed with status code 401"){
            dispatch({
                type: LOGIN_FAIL,
                payload: "Incorrect email or password"
            })
        } else if(error.message === "Request failed with status code 500"){
            dispatch({
                type: LOGIN_FAIL,
                payload: "Invalid username or password"
            })
            dispatch({
                type: LOGIN_FAIL,
                payload: "Please check your internet connection and try again"
            })
        }
     }
}

export const AddProfileImgAdmin = (image, id) => async(dispatch) => {
    try {
        let formData = new FormData();
        formData.append('image', image);

        fetch(`${Prod01}/general/images`, {
            method: 'POST',
            body: formData
        })

    await axios.put(`${Prod01}/admin/edit/profileImg/${id}`, {
        profileImg: image.name
    });

    dispatch({
        type: SHOW_ALERT_MESSAGE,
        payload: "Profile Image Added"
    });

    dispatch({
        type: HIDE_ALERT_MESSAGE
    })

    dispatch(getAdminProfile(id));

    } catch (error) {
        dispatch({
            type: ADMIN_PASSWORD_UPDATE_FAIL,
            payload: "unable to add image"
        })
    }
}


export const sendOTPCode = (_id, email, forAccount) => async (dispatch) => {
    try {

        dispatch({
            type: FETCH_START
        })

        const otp = await axios.post(`${Prod01}/hr/sendOTP`, {
            _id : _id,
            email : email,
            forAccount : forAccount
        });

        if(otp){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: otp?.data?.message
            });

            dispatch({
                type: FETCH_END
            })

        } else {
            dispatch({
                type: FETCH_ERROR,
                payload: 'Email cannot be sent, try later'
              })
        }



    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Email cannot be sent, try later'
          })
    }
}

export const verifyOTPCode = (_id, otp, forAccount,email) => async (dispatch) => {
    try {
        const verify = await axios.post(`${Prod01}/hr/verifyOTP`, {
            userId : _id,
            otp : otp,
            forAccount : forAccount
        });





        if(verify?.data?.status === "Verified"){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: verify?.data?.message
            });
            saveVerification(forAccount, email)

            dispatch({
                type: VERIFIED_ACCOUNT_SUCCESS,
            })
        } else {
            dispatch({
                type: FETCH_ERROR,
                payload: verify?.data?.message
            });
        }

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: "Verification Failed"
          })

          dispatch({
            type: VERIFIED_ACCOUNT_FAILED,
        })
    }
}

const saveVerification = (forAccount, email) => {

    let verifiedAccounts = localStorage.getItem('verifiedAccounts') ? JSON.parse(localStorage.getItem('verifiedAccounts')) : [];
    let toAdd = {forAccount, email};
    if (!verifiedAccounts.includes(toAdd)) {
           verifiedAccounts.push(toAdd);
            localStorage.setItem('verifiedAccounts', JSON.stringify(verifiedAccounts));
    }
}

export const isVerificationSaved = (forAccount, email) => {
    let verifiedAccounts = localStorage.getItem('verifiedAccounts') ? JSON.parse(localStorage.getItem('verifiedAccounts')) : [];
    let toCheck = {forAccount, email};
    for (let i = 0; i < verifiedAccounts.length; i++) {
        if (verifiedAccounts[i].forAccount === toCheck.forAccount && verifiedAccounts[i].email === toCheck.email) {
            return true;
        }
    }
    return false;
}

export const getAdminProfile = (Id) => async(dispatch) => {
    try {
       const res = await axios.post(`${Prod01}/admin/profile/${Id}`);

        dispatch({
            type: FETCH_PROFILE_SUCCESS,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: FETCH_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}


export const changeAdminPassword = (id, oldPassword, newPassword) => async(dispatch) => {
    try {

    await axios.put(`${Prod01}/admin/edit/${id}`, {
        oldPassword: oldPassword,
        password: newPassword
    });

    dispatch({
        type: SHOW_ALERT_MESSAGE,
        payload: "Password Updated"
    });

    dispatch(getAdminProfile(id));

    } catch (error) {
        dispatch({
            type: ADMIN_PASSWORD_UPDATE_FAIL,
            payload: error.message
        })
    }
}


// Assistant Login
export const assistantLogin = (values, recaptchaVal) => async(dispatch) => {
    const {email, password} = values;

    let res;

    try {
       dispatch({
           type: LOGIN_REQUEST
       })

       res = await axios.post(`${Prod01}/assistant/login`, {
        email: email,
        password: password,
        recaptchaValue: recaptchaVal
       });

       if (res.data) {
        axios.defaults.headers.common["authorization"] = "Bearer " + res.data.tokens.access.token;

        localStorage.setItem(
          "token",
          JSON.stringify(res.data.tokens.access.token)
        );
        localStorage.setItem(
          "assistant",
          JSON.stringify(res.data.doctor)
        );
        localStorage.setItem(
            "userType",
            JSON.stringify("assistant")
        )
       }

       dispatch({
           type: LOGIN_SUCCESS,
           payload: res.data.assistant
       })

    } catch (error) {
            dispatch({
                type: LOGIN_FAIL,
                payload: error.response.data.message
            })
        }
    }


// HR Logout
export const hrLogout = () => async(dispatch) => {
    try {

       await axios.post(`${Prod01}/hr/signout`);

       dispatch({
           type: HR_LOGOUT_SUCCESS
       })
        deleteSessionFromStorage()

    } catch (error) {
        dispatch({
            type: HR_LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }


}

// Update Password -> ADMIN
export const updatePassword = (id, name, email, password) => async(dispatch) => {
    try {
       dispatch({
           type: ADMIN_PASSWORD_UPDATE_REQUEST
       })

        await axios.put(`${Prod01}/admin/edit/${id}`, {
           name: name,
           email: email,
           password: password
       });

       dispatch({
        type: SHOW_ALERT_MESSAGE,
        payload: "Account Information updated"
        });

        dispatch(logout());
        window.location.replace("/");

    } catch (error) {
        dispatch({
            type: ADMIN_PASSWORD_UPDATE_FAIL,
            payload: "Unable to Update"
        })
    }
}

// Logout
export const logout = () => async(dispatch) => {
    try {
       dispatch({
           type: LOGOUT_SUCCESS
       })
        deleteSessionFromStorage()

    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Logout
export const staffLogout = () => async(dispatch) => {
    try {

        await axios.post(`${Prod01}/doctor/signout`);
        dispatch({
            type: STAFF_LOGOUT_SUCCESS
        })
        deleteSessionFromStorage()



    } catch (error) {
        dispatch({
            type: STAFF_LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Logout
export const AssistantLogout = () => async(dispatch) => {
    try {
        dispatch({
            type: ASSISTANT_LOGOUT_SUCCESS
        })
        deleteSessionFromStorage()



    } catch (error) {
        dispatch({
            type: ASSISTANT_LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

const deleteSessionFromStorage = () => {
    localStorage.removeItem("token");
    let userType = JSON.parse(localStorage.getItem('userType'));
    localStorage.removeItem(userType);
    localStorage.removeItem('userType')
}

export const forgotpassword = (values) => async(dispatch) => {
    const { email } = values;
    try {
       const res  = await axios.post(`${Prod01}/auth/forgot-password`, {
           email
       });

       if(res){
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Email Sent"
          });
        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
       }


    } catch (error) {
       dispatch({
        type: FETCH_ERROR,
        payload: 'Email cannot be sent'
      })
      dispatch({
        type: HIDE_ALERT_MESSAGE
      })
    }
}

export const resetpassword = (values, queryToken) => async(dispatch) => {
    const { password } = values;
    try {
       const res = await axios.post(`${Prod01}/auth/reset-password?token=${queryToken}`, {
           password: password
       });

       if(res){
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Password has been reset"
          });
        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
       }


    } catch (error) {
       dispatch({
        type: FETCH_ERROR,
        payload: 'Unable to update password'
      })
      dispatch({
        type: HIDE_ALERT_MESSAGE
      })
    }
}

export const adminSignup = (values) => async(dispatch) => {


    try {
        const res = await axios.post(`${Prod01}/admin/signup`, values);

        if(res){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "New Admin Added"
              });
        }
    }
     catch (error) {
        dispatch({
        type: FETCH_ERROR,
        payload: 'Email already exists'
      })
    }
}

export const resetpasswordById = (password, id, entity) => async(dispatch) => {

    try {
       const res = await axios.post(`${Prod01}/general/resetpasswordanyuser`, {
           id: id,
           password: password
       });

       if(entity === 'assistant'){
            await axios.put(`${Prod01}/assistant/edit/${id}`, {
                verified: false
        })}

       if(entity === 'nurse'){
        await axios.put(`${Prod01}/hr/edit/${id}`, {
            verified: false
        })}

        if(entity === 'doctor'){
            await axios.put(`${Prod01}/doctor/edit/${id}`, {
                verified: false
        })}

       if(res){
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Password has been reset"
          });
       }


    } catch (error) {
       dispatch({
        type: FETCH_ERROR,
        payload: 'Unable to update password'
      })
    }
}



// Clear errors
export const clearErrors = () => async(dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    })
}


const shouldTokenBeValid = (timeStamp) => {
    let time = new Date().getTime();
    let diff = time - timeStamp;
    let hoursToBeValid = 24;
    let millisecondsToBeValid = 1000 * 60*60 *hoursToBeValid;
    return diff < millisecondsToBeValid;
}

export const loadSession = async (dispatch) => {

    const token = JSON.parse(localStorage.getItem('token'));
    const userType = JSON.parse(localStorage.getItem('userType'));

    let obj = {};
    if (token) {
        axios.defaults.headers.common["authorization"] = "Bearer " + token;
    }
    if (userType){
        console.log(userType)
        obj = JSON.parse(localStorage.getItem(userType));
        if (obj) {
            let timeStamp = obj.timeStamp;
            if(shouldTokenBeValid(timeStamp)){
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: obj
                })
            }
            else {
                deleteSessionFromStorage()
            }
        }
    }

}


export function SessionWrapper({children}) {

    const dispatch = useDispatch();
    useEffect(async () => {
        await dispatch(loadSession);
        }, []);
        return (
            <StandardErrorBoundary>
                {children}
            </StandardErrorBoundary>
        )
    }

