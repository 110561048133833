import  {useState, useEffect, useRef} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { getMonthlyTelemetryPatients, getPatientTelemetaryReport, getDoctors, getOrganizations } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useReactToPrint } from 'react-to-print';
import MyDocument from '../../components/MyDocument';
import Loader from '../../layouts/Loader';
import {Modal} from 'react-bootstrap';
import {MONTHLY_TELEMETRY_DATA_RESET} from '../../constants/adminConstants';
import Select from 'react-select';


const TelemetryMonthlyReadingsLog = () => {
    
    const componentRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '52px', // Adjust the height here as needed
          width: '250px',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth:'2px',
          borderColor: '#444',
        }),
      };

    const [query, setQuery] = useState("");
    const keys = ["firstname", "lastname"];
    const [doctorId, setDoctorId] = useState("null");
    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(50);    
    const {telemetaryReport} = useSelector(state => state.telemetaryReport);
    const { organizations } = useSelector(state => state.orgs);
    
    const [show, setShow] = useState(false);
    const [orgId, setOrgId] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle:`${telemetaryReport && telemetaryReport[0]?.assigned_patient_id?.lastname}, ${telemetaryReport && telemetaryReport[0]?.assigned_patient_id?.firstname} Telemetry Report ` + moment().tz("America/New_York").format("MM/YYYY"),
        content: () => componentRef.current,
    });

    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'));

    const { loading, error, patients} = useSelector(state => state.monthlyTelemetryPatients);
    const { doctors } = useSelector(state => state.doctor);

    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name]})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }

    useEffect(() => {
        if(error){
            alert.error(error);
        }

        dispatch({
            type: MONTHLY_TELEMETRY_DATA_RESET
        })
        
        dispatch(getDoctors(resPerPage, currentPage));
        dispatch(getOrganizations()); 

    }, [alert, error]);


    const handleDownloadTelemetry = (patientId) => {
        if(startDate && endDate) {  
            dispatch(getPatientTelemetaryReport(patientId, startDate, endDate));         
        }

        setShow(true);
    }

    const searchTelemetryPatientsLog = () => {
        if(doctorId === "null"){
            alert.error('Please select doctor');
            return
        }

        dispatch(getMonthlyTelemetryPatients(startDate, endDate, doctorId));
    }


    return (
    <>
        <MetaData title="Telemetry Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">
                <div className="row-display">    
                    <h5 className="title pt-2 mt-2"><i className="bx bx-heart"></i> &nbsp;Telemetry Monthly Readings Log </h5>
                    <div> 
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/report/telemetary" className="link">
                            Telemetry Report By Patient 
                        </Link>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                    </div>    
                </div>
                <br />
                {/* preview */}
                {telemetaryReport && <>
                    <div style={{ display: "none" }}> 
                        <MyDocument healthData={telemetaryReport} startDate={startDate} endDate={endDate} ref={componentRef} />
                    </div>
                </>}
                
                {/* preview */}
                {loading ? <Loader /> : <>    
                <div className="row reportFormFields" style={{display: 'flex', justifyContent: 'flex-end', marginRight: '10px'}}>                    
                    <div>
                        <label className="form-label">
                            Select Organization  <span style={{color: 'red'}}> *</span>  
                        </label>
                        <Select
                            options={orgOptions}
                            onChange={getOrgProfile}
                            styles={customStyles}
                        />    
                    </div>&nbsp;&nbsp;&nbsp;
                    
                    <div>
                        <label className='form-label'>Select Doctor <span style={{color: 'red'}}> *</span></label>
                            <select className="form-control" 
                                value={doctorId} 
                                onChange={e => setDoctorId(e.target.value)}
                                disabled={orgId ? false : true}
                                >
                                <option value="null">Select Doctor</option>
                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                    <option key={doctor?._id} value={doctor?._id}>
                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                    </option>
                                ))}
                            </select>
                        </div>
                    &nbsp;&nbsp;&nbsp;
                            
                        <div>
                        <label className='form-label'>From <span style={{color: 'red'}}> *</span></label>
                        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control" placeholder="From"/>
                    </div>
                    &nbsp;&nbsp;&nbsp;

                    <div>
                    <label className='form-label'>To <span style={{color: 'red'}}> *</span></label>
                        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")} 
                            className="form-control" placeholder="To"/> 
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className='mt-4'>
                        <button className='edit-pill mt-2' onClick={() => searchTelemetryPatientsLog()}>Search</button>
                    </div>
                    &nbsp;&nbsp;
                </div>
                <br />
                {patients?.length > 0 &&  <>
                    <div className='row-display'>
                        <div className='ml-4'>            
                            <input 
                                type="text" 
                                className="form-control"
                                onChange={e => setQuery(e.target.value)}
                                placeholder="Search Patient . . ." 
                            />
                        </div>
                    </div>
                </>}
                <br />
                

                <div className="col-md-12">
                        {patients?.length > 0 && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Sr.</th>
                                <th>Name</th>
                                <th>DOB </th>
                                <th>Acc Status</th>
                                <th>Clinician</th>
                                <th>Program Enrolled</th>
                                <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {patients && patients.length > 0 ? <> 
                                {patients && patients.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((patient, index) => (
                                <tr key={index}>  
                                <td>{index + 1}</td>
                                <td>
                                    {patient?.block === false ? <Link className="link" 
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        {patient?.lastname}, {patient?.firstname}
                                    </Link> : <p className='text-muted'>
                                        {patient?.lastname}, {patient?.firstname} 
                                    </p>}
                                </td>
                                
                                <td> <b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b> <p>{patient?.gender}</p></td> 
                                
                                
                                <td style={{color: 'green'}}>
                                   <b>{patient?.block === false ? <span style={{color: 'green'}}>
                                    Activated</span> : <span style={{color: 'red'}}>Deactivated</span>}</b>
                                </td> 
                                    
                                {patient?.assigned_doctor_id ? <>
                                <td><b>Dr. {patient?.assigned_doctor_id?.lastname}, {patient?.assigned_doctor_id?.firstname}</b></td>
                                </> : <>
                                <td>---</td>
                                </>}

                    
                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>
                                
                                <td>
                                    <button className="edit-pill"  onClick={() => handleDownloadTelemetry(patient?._id)}>
                                        <i className="bx bx-download"></i>&nbsp; Save
                                        </button>
                                </td>
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            
                            </tbody>
                            
                            </table>  
                        </>}                      
                        </div>
                    </>}
                

                </div>
                </div>

                {/* Modal to download report */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header><h5 className='title'>Telemetry Report Preview</h5></Modal.Header>

                    <Modal.Body>

                        {telemetaryReport && <>
                            <div className='row-display'>

                                <div className='row-display-column'>
                                    <b> Patient Name : </b> <p>{telemetaryReport[0]?.assigned_patient_id?.lastname}, {telemetaryReport[0]?.assigned_patient_id?.firstname} </p>
                                </div>

                                <div className='row-display-column'>
                                    <b> Gender : </b> <p>{telemetaryReport[0]?.assigned_patient_id?.gender === 'male' ? 'Male' : 'Female'}</p>
                                </div>

                                <div className='row-display-column'>
                                    <b>D.O.B : </b> <p>{moment(telemetaryReport[0]?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</p>
                                </div>

                            </div>
                            <br/>
                            <div className='row-display'>
                                <div className='row-display-column'>   
                                    <b> Period : </b>  <p>{moment(startDate).format("MM/DD/YYYY")} to {moment(endDate).format("MM/DD/YYYY")} </p>
                                </div>

                                <div className='row-display-column'>   
                                    <b> Number of Readings : </b>  <p>{telemetaryReport?.length} </p>
                                </div>
                            </div>

                            <br/>
                            <div className='row-display'>
                                <div className='row-display-column'>   
                                    <b> Provider Name : </b>  <p>{telemetaryReport[0]?.assigned_doctor_id?.lastname +' , '+ telemetaryReport[0]?.assigned_doctor_id?.firstname} </p>
                                </div>
                            </div>
                        </>}
                        

                    </Modal.Body>
                    
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleClose}>
                            Close
                        </button>
                        {telemetaryReport?.length > 0 && <>
                            <button className="edit-pill" onClick={() => { handlePrint(); setShow(false)}}>
                                Download .PDF
                            </button>
                        </>}
                        
                    </Modal.Footer>
            </Modal>
        </section>
    </>
  )
}

export default TelemetryMonthlyReadingsLog